<template>
  <b-row class="d-flex align-items-center mb-3 px-3">
    <b-col class="pl-0">
      <h1 class="w-color-primary mt-2 ml-3">{{ heading }}</h1>
    </b-col>
    <b-col v-if="!!buttonLink" class="d-flex justify-content-end pr-0">
      <b-button :to="buttonLink" class="button" variant="outline-primary">{{ buttonText }}</b-button>
    </b-col>
  </b-row>
</template>

<script>
export default {
  props: {
    heading: {
      default: 'Zoznam'
    },
    buttonText: {
      default: 'Pridať'
    },
    buttonLink: {
      default: ''
    }
  }
}
</script>